
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import moment, { Moment } from 'moment';
import DatePicker from 'ant-design-vue/lib/date-picker';
import 'ant-design-vue/lib/date-picker/style/css';

import useView from '@/uses/useView';

import { IFilter } from '@/interfaces/Filter';
import SearchBar from './SearchBar.vue';

export default defineComponent({
  name: 'PageHeader',

  components: {
    DatePicker,
    SearchBar,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    searchDefinition: {
      type: String,
      required: true,
    },
  },

  emits: [
    'open-customize-dialog',
    'open-favorites-form-dialog',
    'query-grid-data',
    'export-xlsx',
  ],

  setup(props, { emit }) {
    const startDate = ref<Moment | undefined>();
    const endDate = ref<Moment>();
    const endOpen = ref<boolean>(false);

    const startDateFilter = ref<IFilter>({
      id: 'reference_date',
      type: 'timestamp',
      index: 0,
      visible: true,
      operation: '>=',
      field_id: 'reference_date',
      data_source: '',
      filter_text: '',
      header_text: 'From',
      default_filter: '',
    });

    const endDateFilter = ref<IFilter>({
      id: 'reference_date',
      type: 'timestamp',
      index: 0,
      visible: true,
      operation: '<=',
      field_id: 'reference_date',
      data_source: '',
      filter_text: '',
      header_text: 'To',
      default_filter: '',
    });

    const { view, filters, updateFilters } = useView();

    onMounted(() => {
      if (filters.value && filters.value.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startDate.value = moment.utc(filters.value[0].filter_text);
        endDate.value = moment.utc(filters.value[1].filter_text);
      }
    });

    const disabledStartDate = (value: Moment) => {
      if (!value || !endDate.value) {
        return false;
      }

      return value.valueOf() > endDate.value.valueOf();
    };

    const disabledEndDate = (value: Moment) => {
      if (!value || !startDate.value) {
        return false;
      }

      return startDate.value.valueOf() >= value.valueOf();
    };

    const handleStartOpenChange = (open: boolean) => {
      if (!open) {
        endOpen.value = true;
      }
    };

    const handleEndOpenChange = (open: boolean) => {
      endOpen.value = open;
    };

    watch(startDate, () => {
      if (startDate.value) {
        startDateFilter.value.filter_text = moment.utc(startDate.value).format('L').toString();
      } else {
        endDate.value = undefined;
        endDateFilter.value.filter_text = '';
      }
    });

    watch(endDate, () => {
      if (endDate.value) {
        endDateFilter.value.filter_text = moment.utc(endDate.value).format('L').toString();
      }

      updateFilters({
        viewName: view.value.name,
        filters: [startDateFilter.value, endDateFilter.value],
      });

      emit('query-grid-data');
    });

    return {
      startDate,
      endDate,
      endOpen,
      disabledStartDate,
      disabledEndDate,
      handleStartOpenChange,
      handleEndOpenChange,
    };
  },
});
