import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { IDocument } from '@/interfaces/Document';

export default function useDocument(): {
  documents: ComputedRef<IDocument[]>;
  fetchDocuments: (customerNo: string) => Promise<void>;
  createDocument: (
    email: string,
    customerNo: string,
    document: string,
    fileName: string,
  ) => Promise<void>;
  deleteDocument: (documentId: number) => Promise<void>;
  downloadDocument: (payload: { documentId: number, fileName: string }) => Promise<void>;
  } {
  const store = useStore();

  const documents = computed((): IDocument[] => store.getters['Documents/getDocuments']);

  async function fetchDocuments(customerNo: string): Promise<void> {
    try {
      await store.dispatch('Documents/fetchDocuments', customerNo);
    } catch (error) {
      console.error(error);
    }
  }

  async function createDocument(
    email: string,
    customerNo: string,
    document: string,
    fileName: string,
  ): Promise<void> {
    try {
      await store.dispatch('Documents/createDocument', {
        email,
        customerNo,
        document,
        fileName,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteDocument(documentId: number): Promise<void> {
    try {
      await store.dispatch('Documents/deleteDocument', documentId);
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadDocument(
    payload: { documentId: number, fileName: string },
  ): Promise<void> {
    try {
      await store.dispatch('Documents/downloadDocument', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    documents,
    fetchDocuments,
    createDocument,
    deleteDocument,
    downloadDocument,
  };
}
