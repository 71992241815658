import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { ISearchResponse } from '@/interfaces/Search';

export default function useDropdown(): {
  searchResults: ComputedRef<ISearchResponse>;
  clearSearchResults: () => void;
  fetchSearchResults: (payload: {
    searchDefinition: string;
    searchValue: string;
  }) => Promise<void>;
  } {
  const store = useStore();

  const searchResults = computed(():ISearchResponse => store.getters['Search/getSearchResults']);

  function clearSearchResults(): void {
    store.commit('Search/CLEAR_SEARCH_RESULTS', []);
  }

  async function fetchSearchResults(payload: {
    searchDefinition: string;
    searchValue: string;
  }): Promise<void> {
    try {
      const response = await store.dispatch('Search/fetchSearchResults', payload);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  return {
    searchResults,
    clearSearchResults,
    fetchSearchResults,
  };
}
