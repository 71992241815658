import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["target", "href"]
const _hoisted_2 = ["target", "href"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    ref: "dataGrid",
    data: _ctx.gridData,
    border: true,
    "max-height": "800",
    "row-key": "id",
    stripe: "",
    class: "table-striped"
  }, {
    empty: _withCtx(() => [
      _createVNode(_component_el_empty, { description: "No Data" })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: index,
          "class-name": `el-text__${column.column_align}`,
          label: column.header_text,
          prop: column.id,
          sortable: "custom",
          "sort-orders": ['ascending', 'descending'],
          width: column.width
        }, {
          default: _withCtx((scope) => [
            (column.extended_properties)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (
              column.extended_properties.hyperlink && column.extended_properties.resource)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (column.extended_properties.tooltip)
                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                              key: 0,
                              placement: "right",
                              enterable: false
                            }, {
                              content: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  target: column.extended_properties.hyperlink.target,
                                  href: scope.row[column.extended_properties.hyperlink.ref_field]
                                }, [
                                  _createVNode(_component_el_image, {
                                    src: 
                    `/images/${scope.row[column.extended_properties.resource.ref_field]}`
                  ,
                                    alt: "",
                                    style: {"width":"28px","height":"28px"},
                                    fill: "fill"
                                  }, null, 8, ["src"])
                                ], 8, _hoisted_1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createElementVNode("a", {
                          target: column.extended_properties.hyperlink.target,
                          href: scope.row[column.extended_properties.hyperlink.ref_field]
                        }, [
                          _createVNode(_component_el_image, {
                            src: 
                  `/images/${scope.row[column.extended_properties.resource.ref_field]}`
                ,
                            alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                            style: {"width":"28px","height":"28px"},
                            fill: "fill"
                          }, null, 8, ["src", "alt"])
                        ], 8, _hoisted_2)
                      ], 64))
                    : (column.extended_properties.hyperlink)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (column.extended_properties.tooltip)
                            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                key: 0,
                                placement: "right",
                                enterable: false
                              }, {
                                content: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("a", {
                                    onClick: ($event: any) => (_ctx.redirectToSingleView(
                  column.id === 'invoice_no' ? scope.row['invoice_id'] : scope.row[column.id],
                  column,
                ))
                                  }, _toDisplayString(scope.row[column.id]), 9, _hoisted_3)
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                onClick: ($event: any) => (_ctx.redirectToSingleView(
                column.id === 'invoice_no' ? scope.row['invoice_id'] : scope.row[column.id],
                column,
              ))
                              }, _toDisplayString(scope.row[column.id]), 9, _hoisted_4))
                        ], 64))
                      : (column.extended_properties.resource)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (column.extended_properties.tooltip)
                              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                  key: 0,
                                  placement: "right",
                                  enterable: false
                                }, {
                                  content: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_image, {
                                      src: 
                  `/images/status/${scope.row[column.extended_properties.resource.ref_field]}`
                ,
                                      alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                                      fill: "fill"
                                    }, null, 8, ["src", "alt"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_el_image, {
                                  key: 1,
                                  src: 
                `/images/status/${scope.row[column.extended_properties.resource.ref_field]}`
              ,
                                  alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                                  fill: "fill"
                                }, null, 8, ["src", "alt"]))
                          ], 64))
                        : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (column.type === 'date')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(scope.row[column.id]
                ? _ctx.moment.utc(scope.row[column.id]).format("MM-DD-YYYY")
                : ''), 1)
                      ], 64))
                    : (column.type === 'money')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(scope.row[column.id] ?
                new Intl.NumberFormat(
                  'en-US', { style: 'currency', currency: 'USD' }
                ).format(scope.row[column.id])
                :
                ''), 1)
                        ], 64))
                      : (column.id === 'notes')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(scope.row.notes), 1)
                          ], 64))
                        : (column.id.startsWith('alert'))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (scope.row[column.id] && scope.row[column.id] > 0)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      class: "el-text__danger",
                                      icon: "exclamation-triangle"
                                    }),
                                    _createVNode(_component_el_badge, {
                                      value: scope.row[column.id],
                                      class: "el-text__danger el-text__small",
                                      type: "danger"
                                    }, null, 8, ["value"])
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                              _createTextVNode(_toDisplayString(column.prefix && scope.row[column.id] ? column.prefix : '') + " " + _toDisplayString(scope.row[column.id]), 1)
                            ], 64))
                ], 64))
          ]),
          _: 2
        }, 1032, ["class-name", "label", "prop", "width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data"]))
}