import { useStore } from 'vuex';

import { computed, ComputedRef } from 'vue';

import {
  INoteQueryRequest,
  INoteQueryResponse, INoteRequest, INotesRequest, INotesResponse,
} from '@/interfaces/Note';

export default function useNote(): {
  notesResponse: ComputedRef<INotesResponse>;
  noteQueryResponse: ComputedRef<INoteQueryResponse>;
  fetchNotes: (payload: INotesRequest) => Promise<void>;
  queryNote(payload: INoteQueryRequest): Promise<void>;
  createNote: (payload: INoteRequest) => Promise<void>;
  } {
  const store = useStore();

  const notesResponse = computed((): INotesResponse => store.getters['Note/getNotesResponse']);

  const noteQueryResponse = computed((): INoteQueryResponse => store.getters['Note/getNoteQueryResponse']);

  async function fetchNotes(payload: INotesRequest): Promise<void> {
    try {
      await store.dispatch('Note/fetchNotes', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function queryNote(payload: INoteQueryRequest): Promise<void> {
    try {
      await store.dispatch('Note/queryNote', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function createNote(payload: INoteRequest): Promise<void> {
    try {
      await store.dispatch('Note/createNote', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    notesResponse,
    noteQueryResponse,
    fetchNotes,
    queryNote,
    createNote,
  };
}
