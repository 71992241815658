import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "el-text__primary" }
const _hoisted_2 = { class: "el-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.notesResponse.notes.dimension_notes,
    stripe: "",
    height: "40vh"
  }, {
    empty: _withCtx(() => [
      _createVNode(_component_el_empty, { description: "No Data" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, { label: "Notes" }, {
        default: _withCtx((scope) => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(scope.row.note), 1),
          _createVNode(_component_el_descriptions, {
            column: 1,
            border: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.dimensions, (dimension, dimensionIndex) => {
                return (_openBlock(), _createBlock(_component_el_descriptions_item, { key: dimensionIndex }, {
                  label: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getColumnHeaderText(dimension.dimension_name)), 1)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(dimension.dimension_value), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}