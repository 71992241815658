
// 3rd party imports
import { Options, Vue } from 'vue-class-component';
// local imports
import { Sidebar } from '@/components/Shared';

@Options({
  components: {
    Sidebar,
  },
})
export default class DashboardLayout extends Vue {
}
