
import { defineComponent, ref, watch } from 'vue';

import { useRouter } from 'vue-router';

import useSearch from '@/uses/useSearch';
import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/View';
import qs from 'qs';

export default defineComponent({
  name: 'SearchBar',

  props: {
    searchDefinition: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const { searchResults, clearSearchResults, fetchSearchResults } = useSearch();

    const { datasetColumns, dataSource, columns } = useView();

    const searchValue = ref<string>('');

    const isPopoverVisible = ref<boolean>(false);

    const search = async (queryString: string) => {
      try {
        await fetchSearchResults({
          searchDefinition: props.searchDefinition,
          searchValue: queryString,
        });
      } catch (error) {
        console.error(error);
      }
    };

    const handleBlur = () => {
      isPopoverVisible.value = false;
    };

    const getColumnHeaderText = (value: string): string => {
      const result = datasetColumns.value.find((column: IColumn) => column.id === value);
      return result && result.header_text ? result.header_text : value;
    };

    const viewSingle = (groupName, value, datasetName) => {
      const result = searchResults.value.groups.find(
        (el) => el.groupName === groupName,
      );

      if (result && result.seeAllAction) {
        // get view columnDefinition

        const filterFieldColumnId = result.seeAllAction.filterfield === 'invoice_id'
          ? 'invoice_no' : result.seeAllAction.filterfield;

        const columnDef = columns.value.find((el) => el.id === filterFieldColumnId);

        if (groupName === 'Invoices' && columnDef) {
          const rawQueryParams = {
            dataSource: dataSource.value,
            column: columnDef,
          };

          const queryParams = qs.stringify(rawQueryParams);
          const newRoute = router.resolve(`/invoices/${value[result.seeAllAction.filterfield]}?${queryParams}`);
          window.open(newRoute.href);
        } else {
          const payload = {
            filterField: result.seeAllAction.filterfield,
            filterValue: value[result.seeAllAction.filterfield],
            datasetName,
            viewName: result.seeAllAction.view,
            gridName: result.seeAllAction.grid,
          };

          const routeData = router.resolve({ name: 'Search', query: payload });
          window.open(routeData.href, '_blank');
        }
      }
    };

    const viewGroup = (groupName, datasetName) => {
      const result = searchResults.value.groups.find(
        (el) => el.groupName === groupName,
      );

      if (result && result.seeAllAction) {
        const payload = {
          filterField: result.seeAllAction.filterfield,
          filterValue: searchValue.value,
          datasetName,
          viewName: result.seeAllAction.view,
          gridName: result.seeAllAction.grid,
        };
        const routeData = router.resolve({ name: 'Search', query: payload });
        window.open(routeData.href, '_blank');
      }
    };

    watch(() => (searchValue.value), (currentValue) => {
      if (currentValue && currentValue !== '') {
        isPopoverVisible.value = true;
      } else {
        clearSearchResults();
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      searchValue,
      isPopoverVisible,
      searchResults,
      search,
      handleBlur,
      getColumnHeaderText,
      viewSingle,
      viewGroup,
    };
  },
});
