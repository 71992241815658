import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { style: {"height":"100vh"} }, {
    default: _withCtx(() => [
      _createVNode(_component_el_aside, { width: "auto" }, {
        default: _withCtx(() => [
          _createVNode(_component_sidebar)
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, { class: "el-header" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header")
            ]),
            _: 3
          }),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}