
import { defineComponent } from 'vue';

import useNote from '@/uses/useNote';
import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/View';

export default defineComponent({
  name: 'NotesTable',

  setup() {
    const { notesResponse } = useNote();

    const { datasetColumns } = useView();

    const getColumnHeaderText = (value: string): string | undefined => {
      const result = datasetColumns.value.find((column: IColumn) => column.id === value);
      return result?.header_text;
    };

    return {
      notesResponse,
      getColumnHeaderText,
    };
  },
});
