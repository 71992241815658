
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import { useRouter } from 'vue-router';

import { ElTable } from 'element-plus';

import moment from 'moment';
import qs from 'qs';

import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/Grid';

export default defineComponent({
  name: 'Table',

  props: {
    columns: {
      type: Array as PropType<IColumn[]>,
      required: true,
    },

    gridData: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const router = useRouter();

    const { dataSource } = useView();

    const dataGrid = ref<InstanceType<typeof ElTable>>();

    const redirectToSingleView = (value, columnDef) => {
      const rawQueryParams = {
        dataSource: dataSource.value,
        column: columnDef,
      };

      const queryParams = qs.stringify(rawQueryParams);
      const newRoute = router.resolve(`/invoices/${value}?${queryParams}`);
      window.open(newRoute.href);
    };

    return {
      dataGrid,
      redirectToSingleView,
      moment,
    };
  },
});
