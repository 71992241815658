
import { defineComponent, onMounted } from 'vue';
import Highcharts from 'highcharts';

export default defineComponent({
  name: 'Chart',

  props: {
    chartName: {
      type: String,
      required: true,
    },

    chartConfig: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    onMounted(() => Highcharts.chart(props.chartName, props.chartConfig));
  },
});
